import { ApplicationName } from '@models/context/application-name';
import { Product } from '@models/context/product';

export const environment = {
  production: true,
  realProduction: false,
  prodDatabase: true,
  keycloak: {
    issuer: 'https://id.preprod.mroads.dev/auth/realms/humanroads',
    clientId: 'gps-preprod',
    adminClientId: 'backoffice-preprod',
    showDebugInformation: false
  },
  analyticsEnvironment: 'preprod',
  adventureUri: '/apis/adventure',
  poiUri: '/apis/poi',
  hostSuffix: {
    [Product.Explore]: 'explore.preprod.mroads.dev',
    [Product.Connect]: 'connect.preprod.mroads.dev',
    [ApplicationName.MyRoad]: 'parcoursup.explore.preprod.mroads.dev',
    [ApplicationName.CmqBtpNumerique]: 'cmq-btp-numerique.explore.preprod.mroads.dev',
    [Product.Show]: 'salon.preprod.mroads.dev',
    [ApplicationName.RegionSud]: 'region-sud.explore.preprod.mroads.dev'
  },
  protocol: 'https://'
};
